import React from 'react'
import styled from 'styled-components'

import { List, ListItem, Required, Strong } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { FormPageExample } from '../../examples/react/pages/FormPageExample'
import { Link } from '../../components/Link'
import { NoteItem } from '../../components/NoteItem'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { ZoomedExample } from '../../components/ZoomedExample'

export const StyledFormPageExample = styled(FormPageExample)`
  [data-note='4'] {
    position: relative;
    transform: none;
  }
`
// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Lomake">
    <Section title="Periaatteet">
      <List variant="unordered">
        <ListItem>
          Lomakkeen avulla käyttäjä voi tallentaa, lähettää tai julkaista samaan
          asiaan kuuluvia tietoja yhdessä kokonaisuudessa.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Lomake voi olla yksisivuinen tai monisivuinen, riippuen asian
              laajuudesta.
            </ListItem>
            <ListItem>
              Lomake on yleensä oma näkymänsä, mutta tarvittaessa yksisivuisen
              lomakkeen voi esittää modaalissa.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          Lomake koostuu kolmesta alueesta; otsikosta, sisällöstä ja
          toiminnoista. Lomakkeella voi olla myös{' '}
          <Code>
            <Link page="StatusBar" />
          </Code>
          .
        </ListItem>
        <ListItem>
          Pakolliset lomake-kentät indikoidaan punaisella tähdellä (
          <Required />) kentän otsikon lopussa.
        </ListItem>
        <ListItem>Lomake-sivulla ei käytetä murupolkua.</ListItem>
      </List>
    </Section>
    <Section title="Rakenne ja toiminnallisuus">
      <NoteItem
        bullet="1"
        title="Otsikkoalue"
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            Lomakkeen otsikkoalueeseen voi kuulua otsikon (
            <Code>
              <Link page="Heading" />
            </Code>
            ) lisäksi huomiolaatikko (
            <Code>
              <Link page="Message" />
            </Code>
            ) ja/tai ingressi (
            <Code>
              <Link page="Lead" />
            </Code>
            ). Pääotsikolla voi olla myös täydentävä lisäotsikko (
            <Code>subHeading</Code>).
          </ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="2"
        title="Sisältöalue"
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            Lomakkeen sisältöalue (
            <Code>
              <Link page="FormLayout" />
            </Code>
            ) jaetaan asiayhteyden mukaan osioihin (<Code>FormLayoutItem</Code>
            ).
          </ListItem>
          <ListItem>
            Osiolla on aina otsikko, jonka alla voi olla osion yleistä
            ohjetekstiä.
          </ListItem>
          <ListItem>
            Otsikon jälkeen lomakekentät (
            <Code>
              <Link page="Input" />
            </Code>
            ,{' '}
            <Code>
              <Link page="Textarea" />
            </Code>
            ,{' '}
            <Code>
              <Link page="RadioGroup" />
            </Code>
            ,{' '}
            <Code>
              <Link page="Checkbox" />
            </Code>
            , jne.) kootaan harmaaseen laatikkoon. Harmaalla laatikolla
            indikoidaan sen sisältävän käyttäjälle täytettäviä kenttiä, mutta
            joukossa voi olla myös read-only -kenttiä.
          </ListItem>
          <ListItem>
            Jos osiossa on pelkästään read-only -kenttiä, kootaan ne valkoiseen
            laatikkoon.
          </ListItem>
          <ListItem>
            Osioihin voidaan laittaa myös haitareita (
            <Code>
              <Link page="Collapsible" />
            </Code>
            ), joiden sisälle lomakekentät on mahdollista piilottaa.
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Haitarit ovat itsessään jo laatikoita, joten ne eivät tule
                laatikon sisään.
              </ListItem>
              <ListItem>
                Useampia haitareita ja näihin mahdollisesti liittyvä painike voi
                olla lomakkeella allekkain ilman laatikkoa.
              </ListItem>
            </List>
          </ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="3"
        title="Toiminnot-alue"
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            Lomakkeen alaosaan kootaan lomakkeen käsittelyyn liittyvät
            toiminnot.
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Lomakkeen päätoiminnolla (<Strong>Tallenna</Strong>/
                <Strong>Lähetä</Strong>/<Strong>Julkaise</Strong>) täytetyt
                tiedot tai tehdyt muutokset hyväksytään.
              </ListItem>
              <ListItem>
                Lomakkeella voi tarvittaessa olla myös muita toimintoja, esim.{' '}
                <Strong>Keskeytä</Strong> ja <Strong>Jatka myöhemmin</Strong>.{' '}
                Katso myös:{' '}
                <Link to="/patternit/lomakkeen-toiminnot">
                  Lomakkeen toiminnot
                </Link>
                .
              </ListItem>
              <ListItem>
                Monisivuisella lomakkeella toimintoalueelle sijoitetaan lisäksi
                lomakenavigaatio. Katso myös:{' '}
                <Link to="/patternit/monisivuisen-lomakkeen-navigaatio">
                  Monisivuisen lomakkeen navigaatio
                </Link>
                .
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            Toimintoalue erotetaan sisältöalueesta väliviivalla (
            <Code>
              <Link page="Divider" />
            </Code>
            ), ellei sisältöalueen viimeinen osio sisällä laatikkoa.
          </ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="4"
        title="StatusBar"
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            Lomakkeen tallennustieto ja tila ovat{' '}
            <Code>
              <Link page="StatusBar" />
            </Code>
            -komponentissa sivun alareunassa. Tämä on pakollinen lomakkeilla,
            joissa on automaattinen tallennus ja muillakin suositeltava.
          </ListItem>
        </List>
      </NoteItem>
      <ZoomedExample
        notes={[{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }]}
      >
        <StyledFormPageExample />
      </ZoomedExample>
    </Section>
    <Section title="Tilat ja muunnelmat">
      <SectionHeading>Modaali</SectionHeading>
      <List variant="unordered">
        <ListItem>
          Lomakkeen sisältöalue voidaan esittää myös modaalissa. Tällöin
          otsikkoalue ja toiminnot-alue muodostuvat{' '}
          <Code>
            <Link page="Modal" />
          </Code>
          -komponentin mukaisella tavalla.
        </ListItem>
        <ListItem>Modaalissa ei ole koskaan automaattitallennusta.</ListItem>
        <ListItem>Modaalin päälle ei voi tulla toista modaalia.</ListItem>
      </List>
      <SectionHeading>Monisivuinen lomake</SectionHeading>
      <List variant="unordered">
        <ListItem>
          Jos lomakkeessa on paljon täytettäviä tietoja, sisältöalueen voi jakaa
          useammalle sivulle. Tällöin puhutaan{' '}
          <Link to="/patternit/monisivuinen-lomake">
            monisivuisesta lomakkeesta
          </Link>
          .
        </ListItem>
      </List>
    </Section>
    <Section title="Esimerkkejä käyttötapauksista">
      <SectionHeading>Asioinnin lomakkeet</SectionHeading>
      <List variant="unordered">
        <ListItem>
          Viranomaisasioinnissa pyydetään käyttäjältä tietoja yleensä
          lomakkeilla. Yksisivuisia lomakkeita ovat esim.{' '}
          <Strong>Työttömyysetuusasian selvityspyyntö</Strong> ja useat työnhaun
          muutoslomakkeet. Monisivuisia lomakkeita ovat esim.{' '}
          <Strong>Työnhakija-asiakkaaksi ilmoittautuminen</Strong>,{' '}
          <Strong>Työllistymissuunnitelma</Strong> sekä osa työnhaun
          muutoslomakkeista.
        </ListItem>
      </List>
      <SectionHeading>Omat tiedot</SectionHeading>
      <List variant="unordered">
        <ListItem>
          Työmarkkinatorin omien tietojen muokkaaminen tehdään lomakkeessa, joka
          on modaalissa.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
